// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-gadzety-reklamowe-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\gadzety-reklamowe.js" /* webpackChunkName: "component---src-pages-oferta-gadzety-reklamowe-js" */),
  "component---src-pages-oferta-kampanie-reklamowe-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\kampanie-reklamowe.js" /* webpackChunkName: "component---src-pages-oferta-kampanie-reklamowe-js" */),
  "component---src-pages-oferta-oklejanie-oznakowanie-pojazdow-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\oklejanie-oznakowanie-pojazdow.js" /* webpackChunkName: "component---src-pages-oferta-oklejanie-oznakowanie-pojazdow-js" */),
  "component---src-pages-oferta-opieka-social-media-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\opieka-social-media.js" /* webpackChunkName: "component---src-pages-oferta-opieka-social-media-js" */),
  "component---src-pages-oferta-reklama-zewnetrzna-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\reklama-zewnetrzna.js" /* webpackChunkName: "component---src-pages-oferta-reklama-zewnetrzna-js" */),
  "component---src-pages-oferta-strony-www-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\strony-www.js" /* webpackChunkName: "component---src-pages-oferta-strony-www-js" */),
  "component---src-pages-oferta-stworzenie-marki-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\stworzenie-marki.js" /* webpackChunkName: "component---src-pages-oferta-stworzenie-marki-js" */),
  "component---src-pages-oferta-ulotki-wizytowki-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\ulotki-wizytowki.js" /* webpackChunkName: "component---src-pages-oferta-ulotki-wizytowki-js" */),
  "component---src-pages-oferta-wideo-spoty-reklamowe-nagrywanie-filmow-dron-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\wideo-spoty-reklamowe-nagrywanie-filmow-dron.js" /* webpackChunkName: "component---src-pages-oferta-wideo-spoty-reklamowe-nagrywanie-filmow-dron-js" */),
  "component---src-pages-oferta-wydruki-wielkoformatowe-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\wydruki-wielkoformatowe.js" /* webpackChunkName: "component---src-pages-oferta-wydruki-wielkoformatowe-js" */),
  "component---src-pages-oferta-zaawanswane-strony-www-systemy-firmowe-aplikacje-mobilne-js": () => import("C:\\Users\\simple32\\WebstormProjects\\DepSite\\src\\pages\\oferta\\zaawanswane-strony-www-systemy-firmowe-aplikacje-mobilne.js" /* webpackChunkName: "component---src-pages-oferta-zaawanswane-strony-www-systemy-firmowe-aplikacje-mobilne-js" */)
}

