import { combineReducers } from "redux"
import {
  windowWReducer,
  windowHReducer,
  menuHReducer,
  scrollPosReducer,
  windowRatioReducer,
  naviWReducer,
  isMobileReducer,
} from "src/store/reducers/sizes"

export default combineReducers({
  windowW: windowWReducer,
  windowH: windowHReducer,
  menuH: menuHReducer,
  scrollPos: scrollPosReducer,
  windowRatio: windowRatioReducer,
  naviW: naviWReducer,
  isMobile: isMobileReducer,
})