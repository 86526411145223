export let scrollPos = null

const SCROLL_POS = 'SCROLL_POS';
export const changeScrollPos = scrollPos => ({ type: SCROLL_POS, scrollPos })

const BG_UNIT = "BG_UNIT";
export const changeBgUnit = bgUnit => ({type: BG_UNIT, bgUnit})

const MENU_H = "MENU_H";
export const changeMenuH = menuH => {
  //console.log("chnageMenuH", menuH)
  return ({type: MENU_H, menuH})
}

const WINDOW_W = "WINDOW_W";
export const changeWindowW = windowW => {
  // console.log("chnageWindowW", windowW)
  return ({type: WINDOW_W, windowW})
}

const WINDOW_H = "WINDOW_H";
export const changeWindowH = windowH => {
  return ({type: WINDOW_H, windowH})
}

const WINDOW_RATIO = "WINDOW_RATIO"
export const changeWindowRatio = windowRatio => {
  return ({type: WINDOW_RATIO, windowRatio})
}

const NAVI_W = "NAVI_W"
export const changeNaviW = naviW => {
  return ({type: NAVI_W, naviW})
}

const IS_MOBILE = "IS_MOBILE"
export const changeIsMobile = isMobile => {
  return ({type: IS_MOBILE, isMobile})
}





export const scrollPosReducer = (state = {scrollPos: 0}, action) => {
  switch (action.type) {
    case SCROLL_POS:
      scrollPos = action.scrollPos
      return { ...state, scrollPos: action.scrollPos}
    default:
      return state;
  }
};

export const menuHReducer = (state = {menuH: 0}, action) => {
  switch (action.type) {
    case MENU_H:
      return {...state, menuH: action.menuH}
    default:
      return state;
  }
};

export const windowWReducer = (state = {windowW: 0}, action) => {
  switch (action.type) {
    case WINDOW_W:
      return {...state, windowW: action.windowW}
    default:
      return state;
  }
};

export const windowHReducer = (state = {windowH: 0}, action) => {
  switch (action.type) {
    case WINDOW_H:
      return {...state, windowH: action.windowH}
    default:
      return state;
  }
};

export const windowRatioReducer = (state = {windowRatio: 1}, action) => {
  switch (action.type) {
    case WINDOW_RATIO:
      return {...state, windowRatio: action.windowRatio}
    default:
      return state
  }
}

export const naviWReducer = (state = {naviW: 0}, action) => {
  switch (action.type) {
    case NAVI_W:
      return {...state, naviW: action.naviW}
    default:
      return state
  }
}

export const isMobileReducer = (state = {isMobile: false}, action) => {
  switch (action.type) {
    case IS_MOBILE:
      return {...state, isMobile: action.isMobile}
    default:
      return state
  }
}
